<template>
  <div style="width:50%;margin: 100px 200px;text-align: left;">
    <el-scrollbar
      :native="false"
      wrapClass="wrap-class"
      wrapStyle="max-height:800px;"
      viewClass="view-class"
      :noresize="true"
    >
      <el-timeline>
        <el-timeline-item
          v-for="(file, index) in zipfiles"
          :key="index"
          :timestamp="file.time"
          placement="top"
        >
          <el-card>
            <h4 v-text="file.name"></h4>
            <p>
              {{ file.size
              }}<el-link
                :href="file.path"
                type="primary"
                style="margin-left: 15px;"
                >下载</el-link
              >
            </p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zipfiles: {}
    };
  },
  created() {
    this.$request.get("/api/doc/dataTmpFiles").then(data => {
      data.data.sort((a, b) => b.time.localeCompare(a.time));
      this.zipfiles = data.data;
    });
  }
};
</script>
